import { useStoreContext } from '@/src/packages/components/contexts/store-context-provider.context';
import { IFetchProductPriceListByCountry } from '@bitmap/contracts';

export const useProductPrice = (
  productPriceList: IFetchProductPriceListByCountry
) => {
  const { currencyCode, shippingCountry } = useStoreContext();

  const country = productPriceList.find(
    (item) => item.countryIso === shippingCountry
  );

  const currency = country?.priceList.find(
    (price) => price.currencyIsoCode === currencyCode
  );

  return currency;
};

export const getProductPrice = ({
  productPriceList,
  shippingCountry,
  currencyCode,
}: {
  productPriceList: IFetchProductPriceListByCountry;
  shippingCountry: string | undefined;
  currencyCode: string | undefined;
}) => {
  const country = productPriceList.find(
    (item) => item.countryIso === shippingCountry
  );

  const currency = country?.priceList.find(
    (price) => price.currencyIsoCode === currencyCode
  );

  return currency;
};
