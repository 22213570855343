'use client';
import { CommerceUtils, IFetchShippingRates } from '@bitmap/contracts';
import { Box } from '../Box';
import { Divider } from '../Divider';
import { Stack } from '../Stack';
import { useProductPrice } from '@/src/packages/hooks/use-product-price';
import { Flex } from '@/src/packages/components/ui/Flex';
import { Country } from '@/src/packages/components/ui/Country';
import { sortBy } from 'lodash';
import { useStoreContext } from '@/src/packages/components/contexts/store-context-provider.context';
import { BasketFreeShippingProgress } from '@/src/packages/components/ui/Basket/BasketFreeShippingProgress';
import { useLocaleDrawer } from '@/src/packages/hooks/use-basket-drawer';

const Price = ({ item }: { item: IFetchShippingRates[0]['services'][0] }) => {
  const price = useProductPrice(item.service.priceLists);

  const freeShippingThreshold = useProductPrice(
    item.service.freeShippingThresholds,
  );

  return (
    <Box className="flex items-center text-right">
      {item.service.priceCents === 0 ? (
        <Box>Free</Box>
      ) : (
        <Stack spaceX={2} direction="horizontal" className="flex items-center">
          <Box className="leading-none">{price?.priceGrossFormatted}</Box>

          {item.service.freeShippingThresholdCents && (
            <Box className="text-text-secondary rounded-sm border px-[5px] pb-[4px] pt-[3px] text-xs font-medium uppercase leading-none">
              Free above {freeShippingThreshold?.priceGrossFormatted}
            </Box>
          )}
        </Stack>
      )}
    </Box>
  );
};

export const ShippingRates = ({
  shippingRates,
}: {
  shippingRates: IFetchShippingRates;
}) => {
  const { handleOpen: handleOpenLocaleSelector } = useLocaleDrawer();

  const getZonesWithCountryNames = (countryIsoCodes: string[]) => {
    const result: { iso: string; name: string }[] = [];

    countryIsoCodes.map((code) => {
      const country = CommerceUtils.country.getCountryByIsoCode(code)?.country;

      if (country) {
        result.push({
          name: country,
          iso: code,
        });
      }
    });

    return result;
  };

  const { shippingCountry } = useStoreContext();

  const ratesForSelectedCountry = shippingRates.find((rate) =>
    rate.countryIsoCodes.includes(shippingCountry),
  );

  return (
    <Stack spaceY={5} divider={<Divider />}>
      <Box className="border p-5">
        <Box className="flex items-center leading-none">
          <Box className="flex space-x-1">
            <Box>Delivery to</Box>
            <Box className="font-medium">
              <Country countryIsoCode={shippingCountry} />
            </Box>
          </Box>
          <Box
            className="text-text-secondary hover:text-text-primary ml-auto text-sm"
            as="button"
            onClick={handleOpenLocaleSelector}
          >
            Change
          </Box>
        </Box>

        <Divider className="my-4" />
        <BasketFreeShippingProgress />
      </Box>

      {shippingRates.map((zone, i) => {
        const countries = sortBy(
          getZonesWithCountryNames(zone.countryIsoCodes),
          (c) => c.name,
        ).map((c) => c.name);

        return (
          <Stack spaceY={2} key={i}>
            <Box className="font-medium">{zone.name}</Box>

            {countries.length > 1 && (
              <Box className="text-text-secondary flex-1 text-sm">
                {countries.join(', ')}
              </Box>
            )}

            <Box>
              {zone.services.map((item, i) => {
                return (
                  <Flex key={i}>
                    <Box className="flex-1">{item.service.name}</Box>
                    <Box className="text-right">
                      <Price item={item} />
                    </Box>
                  </Flex>
                );
              })}
            </Box>
          </Stack>
        );
      })}
    </Stack>
  );
};
