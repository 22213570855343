import { useBasket } from '@/src/packages/components/contexts/basket.context';
import { useStoreContext } from '@/src/packages/components/contexts/store-context-provider.context';
import { Box } from '@/src/packages/components/ui/Box';
import { Country } from '@/src/packages/components/ui/Country';
import { Strong } from '@/src/packages/components/ui/Strong';

export const BasketFreeShippingProgress = () => {
  const { basket } = useBasket();

  const { shippingCountry } = useStoreContext();

  const freeShippingServiceWithLowestMinimum = basket?.shippingServices
    .filter((service) => service.freeShippingThresholdCents !== null)
    .sort((a, b) => {
      if (
        a.freeShippingThresholdCents === null ||
        b.freeShippingThresholdCents === null
      ) {
        return 0;
      }
      return a.freeShippingThresholdCents - b.freeShippingThresholdCents;
    })[0];

  if (freeShippingServiceWithLowestMinimum) {
    const {
      freeShippingThresholdMet,
      freeShippingBalanceFormatted,
      freeShippingThresholdCompletePercentage,
    } = freeShippingServiceWithLowestMinimum;

    return (
      <Box className="space-y-3">
        {!freeShippingThresholdMet ? (
          <Box className="leading-none">
            You are <Strong>{freeShippingBalanceFormatted}</Strong> away from{' '}
            <Strong>free delivery</Strong> to{' '}
            <Strong>
              <Country
                countryIsoCode={
                  basket.address?.countryIsoCode || shippingCountry
                }
              />
            </Strong>
          </Box>
        ) : (
          <Box>
            Your order qualifies for <Strong>free delivery</Strong> to{' '}
            <Strong>
              <Country
                countryIsoCode={
                  basket.address?.countryIsoCode || shippingCountry
                }
              />
            </Strong>
          </Box>
        )}

        <Box className="bg-background-secondary relative h-[4px]">
          <Box
            className="absolute left-0 top-0 h-full w-1/2 bg-black"
            style={{
              width: `${freeShippingThresholdCompletePercentage}%`,
            }}
          />
        </Box>
      </Box>
    );
  }

  return <></>;
};
