import { ReactNode } from 'react';
import { Divider, Stack } from '..';

export const PageSectionStack = ({ children }: { children: ReactNode }) => {
  return (
    <Stack spaceY={8} divider={<Divider />}>
      {children}
    </Stack>
  );
};
