'use client';
import { Box } from '@/src/packages/components/ui/Box';
import { ILink } from '@/src/packages/components/ui/Footer/Footer.config';
import { Stack } from '@/src/packages/components/ui/Stack';
import { cn } from '@/src/packages/utils/cn';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { ReactNode } from 'react';

const SidebarLink = (props: { children: ReactNode; href: string }) => {
  const { children, href } = props;

  const path = usePathname();

  const isActive = path?.includes(href);

  return (
    <Box
      className={cn(
        'text-text-secondary hover:text-text-primary leading-none',
        {
          'text-text-primary': isActive,
        },
      )}
      asChild
    >
      <Link href={href}>{children}</Link>
    </Box>
  );
};

export const Sidebar = ({ links }: { links: ILink[] }) => {
  return (
    <Stack spaceY={2} className={cn('sticky top-4 flex-1 transition-all', {})}>
      {links.map((link, i) => {
        return (
          <SidebarLink href={link.href} key={i}>
            {link.title}
          </SidebarLink>
        );
      })}
    </Stack>
  );
};
