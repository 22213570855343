'use client';
import {
  Box,
  Container,
  Heading,
  Link,
  PageSection,
  Paragraph,
} from '@/src/packages/components';
import { IFetchShippingRates } from '@bitmap/contracts';
import { ShippingRates } from '@/src/packages/components/ui/ShippingRates';
import { PageSectionStack } from '@/src/packages/components/templates/PageSectionStack';
import { useStoreContext } from '@/src/packages/components/contexts/store-context-provider.context';
import { TemplateSidebar } from '@/src/packages/components/templates/TemplateSidebar';
import { CustomerSupportLinks } from '@/src/packages/components/ui/Footer/Footer.config';
import NextLink from 'next/link';
import { getRoute } from '@/src/packages/utils/routes';
import { paths } from '@/src/packages/routes';

export const ShippingReturnsInfo = ({
  shippingRates,
}: {
  shippingRates: IFetchShippingRates;
}) => {
  return (
    <PageSectionStack>
      <PageSection>
        <Heading as="h2" size="h2" marginBottom={3} fontWeight="medium">
          Delivery
        </Heading>
        <Box marginBottom={5}>
          <ShippingRates shippingRates={shippingRates} />
        </Box>
        <Paragraph className="text-text-secondary">
          Please note that orders delivered outside of the UK may be subject to
          import duties and customs fees on delivery.
        </Paragraph>
      </PageSection>
      <Box>
        <Heading as="h2" size="h2" marginBottom={3} fontWeight="medium">
          Returns
        </Heading>
        <Paragraph>
          We accept returns up to 30 days after delivery. The goods must be
          unused and in their original condition. Unfortunately, we cannot
          accept returns for items that have been used, unless the reason for
          the return is a manufacturing defect. Please refer to our{' '}
          <Link asChild variant="solid">
            <NextLink href={getRoute({ route: paths.LIFETIME_WARRANTY_INDEX })}>
              Lifetime Warranty
            </NextLink>
          </Link>{' '}
          for more information.
        </Paragraph>
      </Box>
    </PageSectionStack>
  );
};

export const ShippingReturns = () => {
  const { shippingRates } = useStoreContext();

  return (
    <TemplateSidebar title="Delivery & returns" links={CustomerSupportLinks}>
      <Box className="max-w-2xl">
        <ShippingReturnsInfo shippingRates={shippingRates} />
      </Box>
    </TemplateSidebar>
  );
};
