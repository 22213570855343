import { paths } from '@/src/packages/routes';
import { getRoute } from '@/src/packages/utils/routes';

type ILinkSection = {
  title: string;
  links: ILink[];
};

export type ILink = {
  title: string;
  href: string;
  blank?: boolean;
};

export const PolicyLinks = [
  {
    title: 'Lifetime warranty',
    href: getRoute({ route: paths.LIFETIME_WARRANTY_INDEX }),
  },
  {
    title: 'Privacy policy',
    href: getRoute({ route: paths.PRIVACY_INDEX }),
  },
  {
    title: 'Terms & conditions',
    href: getRoute({ route: paths.TERMS_INDEX }),
  },
  {
    title: 'Accessibility statement',
    href: getRoute({ route: paths.ACCESSIBILITY_INDEX }),
  },
  {
    title: 'Modern slavery statement',
    href: getRoute({ route: paths.MODERN_SLAVERY_STATEMENT_INDEX }),
  },
];

export const CustomerSupportLinks = [
  {
    title: 'FAQs',
    href: getRoute({ route: paths.FAQS_INDEX }),
  },
  {
    title: 'Product care',
    href: paths.PRODUCT_CARE_INDEX.path,
  },
  {
    title: 'Delivery & returns',
    href: getRoute({ route: paths.DELIVERY_RETURNS_INDEX }),
  },
  {
    title: 'Size guides',
    href: getRoute({ route: paths.SIZE_GUIDES_INDEX }),
  },
  {
    title: 'Newsletter',
    href: getRoute({ route: paths.NEWSLETTER_INDEX }),
  },
  {
    title: 'Contact',
    href: getRoute({ route: paths.CONTACT_INDEX }),
  },
];

export const ManufacturingLinks = [
  {
    title: 'Processes',
    href: getRoute({ route: paths.MANUFACTURING_INDEX }),
  },
  {
    title: 'Materials',
    href: getRoute({ route: paths.MATERIALS_INDEX }),
  },
  {
    title: 'Suppliers',
    href: getRoute({ route: paths.MAKERS_INDEX }),
  },
];

export const FooterLinksConfig: ILinkSection[] = [
  {
    title: 'Customer support',
    links: CustomerSupportLinks,
  },

  {
    title: 'Company',
    links: [
      {
        title: 'About',
        href: getRoute({ route: paths.ABOUT_INDEX }),
      },
      {
        title: 'Journal',
        href: getRoute({ route: paths.BLOG_INDEX }),
      },
      {
        title: 'Influencers',
        href: getRoute({ route: paths.INFLUENCERS_INDEX }),
      },
      {
        title: 'Reviews',
        href: getRoute({ route: paths.REVIEWS_INDEX }),
      },
      {
        title: 'Press',
        href: getRoute({ route: paths.PRESS_INDEX }),
      },
    ],
  },
  {
    title: 'Policies',
    links: PolicyLinks,
  },
  {
    title: 'Transparent manufacturing',
    links: ManufacturingLinks,
  },
];
