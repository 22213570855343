import { ReactNode } from 'react';
import {
  Box,
  Container,
  Divider,
  Heading,
  Stack,
} from '@/src/packages/components';
import { Sidebar } from '@/src/packages/components/ui/Sidebar';
import { ILink } from '@/src/packages/components/ui/Footer/Footer.config';
import Image from 'next/image';
import {
  Breadcrumb,
  IBreadcrumbItem,
} from '@/src/packages/components/ui/Breadcrumb';
import Balancer from 'react-wrap-balancer';

export const TemplateSidebar = ({
  title,
  children,
  links,
  imageSrc,
  breadcrumb,
}: {
  title?: string;
  children: ReactNode;
  links: ILink[];
  imageSrc?: string;
  breadcrumb?: IBreadcrumbItem[];
}) => {
  return (
    <Box className="pb-12">
      <Container>
        <Stack className="space-y-gutter">
          {title && (
            <>
              {imageSrc ? (
                <Box className="relative h-[300px]">
                  <Image
                    src={imageSrc}
                    fill
                    className="object-cover"
                    alt={title}
                  />
                  <Box className="absolute left-0 top-0 flex h-full w-full p-6">
                    <Heading
                      as="h1"
                      className="mt-auto font-serif text-3xl font-medium text-white"
                    >
                      {title}
                    </Heading>
                  </Box>
                </Box>
              ) : (
                <Box className="relative flex h-[300px] items-center justify-center">
                  <Heading
                    as="h1"
                    className="text-center font-serif text-4xl font-medium"
                  >
                    <Balancer>{title}</Balancer>
                  </Heading>
                </Box>
              )}
            </>
          )}

          <Box className="grid grid-cols-12">
            <Box className="hidden lg:col-span-4 lg:block">
              <Sidebar links={links} />
            </Box>
            <Box className="col-span-12 space-y-4 lg:col-span-8">
              {breadcrumb && <Breadcrumb items={breadcrumb} />}
              <Box>{children}</Box>
            </Box>
          </Box>
        </Stack>
      </Container>
    </Box>
  );
};
